import { ReactNode } from 'react'
import { Block } from 'ui-kit/blocks'
import { Heading, Text } from 'ui-kit/typography'
import { LINKS } from 'routes/constants'
import { BadgesG2 } from 'routes/components/Features/BadgesG2/BadgesG2'
import { Link } from 'routes/components/Shared'
import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { companiesData } from './companiesData'
import { CompaniesCard } from './CompaniesCard'
import { CustomerLogos } from './CustomerLogos/CustomerLogos'
import clsx from 'clsx'
import classes from './Companies.module.scss'

export const Companies = ({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: ReactNode
}) => {
  const defaultTitle: ReactNode = (
    <>The best SaaS companies are building with us</>
  )

  const defaultSubtitle: ReactNode = (
    <>
      The #1 choice for forward-thinking SaaS companies. From&nbsp;startups to
      enterprises, any use case, any SaaS category
    </>
  )

  return (
    <Block variant={'shadowMob'} pad={'baseMob'} className={classes.wrapper}>
      <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
        {title || defaultTitle}
      </Heading>

      <Text size={'lg'} balance center className={classes.subtitle}>
        {subtitle || defaultSubtitle}
      </Text>

      <Slider />

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href={LINKS.SOLUTIONS.HEAD}
        >
          Explore Solutions
        </Link>
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.CASE_STUDIES}
        >
          Read Case Studies
        </Link>
      </div>
      <CustomerLogos />
      <BadgesG2 className={classes.badges} />
    </Block>
  )
}

function Slider() {
  const { slidesToRender, carouselSettings } = useCarousel(companiesData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <CompaniesCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
